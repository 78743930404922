import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const StyleGuidePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Style Guide" />
      <article>
        <h1>Style Guide</h1>
        <p>
          The Australian Journal of Anarchist Theory is a project established to
          produce theory and analysis of history, organisation and politics
          informed by struggles past and present. We aim to prove the relevance of
          anarchist-communist theory and practice to the struggle of the working
          class for liberation. Part of this task is the development of a body of
          theory produced not only in dialogue with anarchists in Australia and
          overseas but with the broader working class and socialist movement.
        </p>
        <p>Publications have played a vital role in the development of the
          anarchist movement. While they have been historically vital, there is a
          lack of publication in the contemporary Australian scene.</p>
        <p>
          When presenting our theory, it helps to have a set of guidelines to
          ensure the content is consistent, accessible and politically astute.
        </p>
        <h2>Language</h2>
        <p>Express yourself as simply and clearly as possible. While certain topics
          inevitably involve a degree of specificity and particular language, most
          things can be expressed without sounding as though you are writing for a
          French philosophy journal.</p>
        <p>Where titles in languages other than english are given, please provide a
          translation. Where a word that does not have a clear translation in
          English is used, please attempt to provide some context to why you are
          employing it.</p>
        <p>When using acronyms for the first time in an essay, write out the whole
          title. For example, Confederación Nacional Trabajador (CNT), and use CNT
          subsequently throughout the essay.</p>
        <p>Avoid sentences becoming too long. Read your writing out loud. If it
          sounds awkward, it probably reads badly too. Do not use too many commas.
          Anything more than two or three in a sentence is most likely too many.</p>
        <h2>Formatting and Structure</h2>
        <p>
          Breaking an article into a simple to follow structure makes it stronger.
          Paragraphs should flow logically, avoiding repetition of topics or
          random tangents. There should be a clearly demarcated introduction and
          conclusion. The introduction should touch on what will be raised by each
          section of the piece, and the conclusion should sum up the conclusions
          of each section.</p>
        <p>
          Please ensure all drafts submitted are formatted with consistent
          indentation, font sizes etc.</p>
        <p>Articles should be at least 2000 words, with 8000 as the maximum limit.</p>
        <h2>Quoting</h2>
        <p>
          When directly quoting, indent the text into the page and use quotation
          marks. When using partial quotes, use quotation marks where the quote
          begins and ends in the sentence, with the reference at the end of the
          sentence.</p>
        <h2>Referencing</h2>
        <p>
          AJAT editor’s request that all referencing is done according <em>Modern
            Language Association (MLA)</em> style. You can find a guide to how to use it
          <a href="https://owl.purdue.edu/owl/research_and_citation/mla_style/mla_style_introduction.html"> here</a>.
          Consistent referencing style makes life much easier for publishers and
          helps keep the journal presentable. Please attempt to reference whenever
          employing someone else’s ideas or referring to historical documentation.</p>
        <p>
          It is preferable to use as many primary sources as possible. While it is
          ok to use secondary sources when analysing someone else’s
          interpretations of an event or topic, relying too heavily on secondary
          sources can be a problem. Ideas and events can tend to get distorted as
          a continuous stream of writers quote and reference one another.</p>
      </article>
    </Layout>
  )
}

export default StyleGuidePage

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`
